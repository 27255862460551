// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E57162', // red from your palette
    },
    secondary: {
      main: '#FFCB77', // Yellow from your palette
    },
    bigh: {
      main: '#2C395E', // Dark blue from your palette
    },
    smallh: {
      main: '#7DBABA', // Teal from your palette
    },
    LightBlue: {
      main: 'rgba(125, 186, 186, 0.1)',
    },
    
    background: {
      default: '#F7F6F2', // Off-white from your palette#F7F6F2
    },
    white: {
      default: '#FFFFF', // Off-white from your palette#F7F6F2
    },
    markdownText: {
      main: 'rgb(80, 78, 74)', // Custom color for Markdown text
    },
  },
  typography: {
    fontFamily: 'proxima-nova,Roboto Condensed, Arial, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1rem',
    },

  },
});

export default theme;
