import React from 'react';
import { Box, Container, Grid, Stack, Typography, IconButton, Link } from '@mui/material';
import { Instagram as InstagramIcon, LinkedIn as LinkedInIcon, EmailOutlined as EmailIcon, PhoneOutlined as PhoneIcon, LocationOnOutlined as LocationIcon } from '@mui/icons-material';
import NewsletterForm from './NewsletterForm'; // Import the new NewsletterForm component

const logoStyle = {
  width: '140px',
  height: 'auto'
};

function Copyright() {
  return (
    <Typography variant="body2" color="common.white" mt={1}>
      {'© '}
      <Link href="#" color="inherit">
        2024 Σοφία Χαρισοπούλου
      </Link>
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'bigh.main',
        color: 'common.white',
        py: 8
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Newsletter Section */}
          <Grid item xs={12} md={8}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Μάθε περισσότερα για την ψυχολογία παιδιών και εφήβων
            </Typography>
            <Typography variant="body2">
            Κάνε εγγραφή στο newsletter για συμβουλές και χρήσιμες πρακτικές. 
            </Typography>
            {/* Using the NewsletterForm component */}
            <NewsletterForm />
          </Grid>

          {/* Social Media Section */}
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
            <Typography variant="body2">Social Media</Typography>
            <Stack direction="row" spacing={1} justifyContent={{ xs: 'center', md: 'flex-end' }}>
              <IconButton
                color="inherit"
                href="https://www.instagram.com/peri_paidiou/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  transition: 'color 0.3s, transform 0.3s',
                  '&:hover': {
                    color: 'primary.main',
                    transform: 'scale(1.1)'
                  }
                }}
              >
                <InstagramIcon sx={{ fontSize: 30 }} />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://www.linkedin.com/in/sofia-charisopoulou/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  transition: 'color 0.3s, transform 0.3s',
                  '&:hover': {
                    color: 'primary.main',
                    transform: 'scale(1.1)'
                  }
                }}
              >
                <LinkedInIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ width: '100%', borderTop: '1px solid', borderColor: 'divider', mt: 4, mb: 4 }}></Box>

        <Grid container spacing={4}>
          {/* Logo and Address Section */}
          <Grid item xs={12} md={4}>
            <img src={'/images/logo/csLogo-07.png'} alt="Logo" style={logoStyle} />
            <Copyright />
          </Grid>

          {/* Contact Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 2 }}>
              Επικοινωνία
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <EmailIcon sx={{ mr: 1 }} />
              <Link href="mailto:sofiacharis@gmail.com" color="inherit" underline="hover">
                sofiachariss@gmail.com
              </Link>
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <PhoneIcon sx={{ mr: 1 }} />
              6972155856
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <LocationIcon sx={{ mr: 1 }} />
              Ιωάννινα
            </Typography>
          </Grid>

          {/* Terms and Policies Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 2 }}>
              Όροι & Πολιτικές
            </Typography>
            <Link href="/privacy-policy" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
              Πολιτική Απορρήτου
            </Link>
            <Link href="/personal-data" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
              Προσωπικά Δεδομένα
            </Link>
            <Link href="/terms-of-use" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
              Όροι Χρήσης
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
