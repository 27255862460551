import React from 'react';
import ReactDOM from 'react-dom/client'; // Use the new API for React 18
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './index.css';  // Import the global CSS file

// Create root using the new API
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
