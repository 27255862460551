import React from 'react';
import { Box, useTheme } from '@mui/material';

const ProgressBar = ({ currentStep }) => {
  const theme = useTheme();
  const totalSteps = 4;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: { xs: '100%', sm: '500px' },
        margin: '0 auto',
        px: { xs: 1, sm: 0 },
      }}
    >
      {[...Array(totalSteps)].map((_, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {/* Circle */}
          <Box
            sx={{
              width: { xs: '16px', sm: '24px' },
              height: { xs: '16px', sm: '24px' },
              borderRadius: '50%',
              backgroundColor:
                index <= currentStep
                  ? theme.palette.primary.main
                  : theme.palette.grey[300],
              zIndex: 1,
            }}
          />

          {/* Dash */}
          {index < totalSteps - 1 && (
            <Box
              sx={{
                width: { xs: '50px', sm: '90px' },
                height: { xs: '2px', sm: '4px' },
                backgroundColor:
                  index < currentStep
                    ? theme.palette.primary.main
                    : theme.palette.grey[300],
                mx: { xs: 0.5, sm: 1.5 },
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ProgressBar;
