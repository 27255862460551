import React from 'react';
import { Typography, Box, Button, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { el } from 'date-fns/locale';
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook

const DateStep = ({ formik, handleNext, handleBack }) => {
  const theme = useTheme(); // Access the theme

  return (
    <Box sx={{ textAlign: 'center' }}>
      {/* Title */}
      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        Επιλέξτε Ημερομηνία
      </Typography>

      {/* Date Picker */}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={el}>
        <DatePicker
          id="date"
          name="date"
          label="Ημερομηνία"
          value={formik.values.date}
          onChange={(value) => formik.setFieldValue('date', value)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
              sx={{
                mt: 2,
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '30px',
                },
              }}
            />
          )}
        />
      </LocalizationProvider>

      {/* Navigation Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
          variant="outlined"
          onClick={handleBack}
          sx={{
            textTransform: 'none',
            borderRadius: '30px',
            borderColor: theme.palette.primary.main, // Use theme color
            color: theme.palette.primary.main, // Use theme color for text
          }}
        >
          Πίσω
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={!formik.values.date}
          sx={{
            backgroundColor: theme.palette.primary.main, // Use theme color
            borderRadius: '30px',
            textTransform: 'none',
          }}
        >
          Κλείσε Ραντεβού
        </Button>
      </Box>
    </Box>
  );
};

export default DateStep;
