import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, IconButton, Fade } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import ServiceStep from './ServiceStep';
import ContactDetailsStep from './ContactDetailsStep';
import DateStep from './DateStep';
import ResultStep from './ResultStep';
import ProgressBar from './ProgressBar';
import { sendEmail } from './emailService';

// Validation schema for the form
const validationSchema = yup.object({
  service: yup.string().required('Επιλέξτε μια υπηρεσία'),
  name: yup
    .string()
    .required('Εισάγετε το όνομά σας')
    .matches(
      /^[A-Za-zΑ-Ωα-ωάέήίόύώΆΈΉΊΌΎΏ\s]+$/,
      'Το όνομα μπορεί να περιέχει μόνο ελληνικά ή λατινικά γράμματα και κενά'
    ),
  phone: yup
    .string()
    .required('Εισάγετε το τηλέφωνό σας')
    .matches(/^\d{10}$/, 'Το τηλέφωνο πρέπει να περιέχει 10 αριθμούς'),
  email: yup
    .string()
    .email('Εισάγετε ένα έγκυρο email')
    .required('Εισάγετε το email σας'),
  date: yup
    .date()
    .required('Επιλέξτε μια ημερομηνία')
    .min(new Date(), 'Η ημερομηνία πρέπει να είναι στο μέλλον'),
});

const BookingPopup = ({ open, onClose }) => {
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState(null);

  const formik = useFormik({
    initialValues: {
      service: '',
      name: '',
      phone: '',
      email: '',
      date: new Date(),
    },
    validationSchema,
    onSubmit: (values) => {
      const templateParams = {
        service: values.service,
        name: values.name,
        phone: values.phone,
        email: values.email,
        date: format(values.date, 'dd/MM/yyyy'),
      };

      sendEmail(templateParams)
        .then(() => {
          setStatus('success');
          setStep(3);
        })
        .catch((error) => {
          console.error('FAILED TO SEND EMAIL', error);
          setStatus('error');
          setStep(3);
        });
    },
  });

  const handleNext = () => {
    if (step === 2) {
      formik.handleSubmit();
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleModalClose = () => {
    formik.resetForm();
    setStep(0);
    setStatus(null);
    onClose();
  };

  // Focus management for accessibility
  const firstInputRef = useRef(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        firstInputRef.current?.focus();
      }, 0);
    }
  }, [open, step]);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      closeAfterTransition
      aria-labelledby="booking-popup-title"
      aria-describedby="booking-popup-description"
    >
      <Fade in={open}>
        <Box sx={modalStyle} role="dialog" aria-modal="true">
          <IconButton
            aria-label="Close booking popup"
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={handleModalClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Image and Progress Bar */}
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Box
              component="img"
              src="/images/popup_booking/icons.png"
              alt="Booking illustration"
              sx={{
                width: '100%',
                maxWidth: { xs: '100%', sm: '550px' },
                height: 'auto',
              }}
            />
          </Box>

          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <ProgressBar currentStep={step} />
          </Box>

          {/* Render the appropriate step component */}
          <Box
            sx={{ px: { xs: 1, sm: 3 }, py: { xs: 2, sm: 3 } }}
            id="booking-popup-description"
          >
            {step === 0 && (
              <ServiceStep
                formik={formik}
                handleNext={handleNext}
                firstInputRef={firstInputRef}
              />
            )}
            {step === 1 && (
              <ContactDetailsStep
                formik={formik}
                handleNext={handleNext}
                handleBack={handleBack}
                firstInputRef={firstInputRef}
              />
            )}
            {step === 2 && (
              <DateStep
                formik={formik}
                handleNext={handleNext}
                handleBack={handleBack}
                firstInputRef={firstInputRef}
              />
            )}
            {step === 3 && (
              <ResultStep status={status} onClose={handleModalClose} />
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '80%', md: '60%', lg: '50%', xl: '40%' },
  maxWidth: { xs: 'none', sm: 600 },
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: { xs: 2, sm: 4 },
  borderRadius: '30px',
  outline: 'none',
  overflowY: 'auto',
};

export default BookingPopup;
