import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Button, Container, Box, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import BurgerMenu from './BurgerMenu';
import BookingButton from './BookingPopup/BookingButton'; // Import the BookingButton component

const Navbar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const location = useLocation();

  // Function to handle scroll event
  const handleScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);

  // Function to toggle drawer state
  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen((prevOpen) => !prevOpen);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Style objects for reusable styles
  const buttonStyle = {
    textTransform: 'none',
    color: theme.palette.bigh.main,
    mx: 0.25,
    px: 3,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '0',
      height: '2px',
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.primary.main,
      transition: 'width 0.3s',
    },
    '&:hover::after': {
      width: '100%',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  };

  const appBarStyle = {
    backgroundColor: scrollPosition > 0 ? 'rgba(255, 255, 255, 0.5)' : 'transparent',
    backdropFilter: scrollPosition > 0 ? 'blur(10px)' : 'none',
    boxShadow: scrollPosition > 0 ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
    transition: 'background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    m: 0,
  };

  return (
    <>
      <AppBar position="fixed" sx={appBarStyle}>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            m: 0,
            p: 0,
            maxWidth: '100%!important',
          }}
        >
          {/* Logo Section */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              component="img"
              src="/images/logo/csLogo-10.png"
              alt="Logo"
              sx={{ height: 70, mr: 2, cursor: 'pointer' }}
              onClick={() => (window.location.href = '/')} // Navigate to home page on click
            />
          </Box>

          {/* Navigation Buttons for Desktop */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', ml: 'auto' }}>
            <Button component={Link} to="/" sx={buttonStyle}>
              Αρχική
            </Button>
            <Button component={Link} to="/βιογραφία" sx={buttonStyle}>
              Βιογραφικό
            </Button>
            <Button component={Link} to="/υπηρεσίες" sx={buttonStyle}>
              Υπηρεσίες
            </Button>
            <Button component={Link} to="/άρθρα" sx={buttonStyle}>
              Άρθρα
            </Button>
            <Button component={Link} to="/επικοινωνία" sx={buttonStyle}>
              Επικοινωνία
            </Button>
            {/* Booking Button */}
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <BookingButton buttonText="Κλείσε Ραντεβού" />
            </Box>
          </Box>

          {/* Burger Menu for Mobile */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            <IconButton
              color="primary"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, transition: 'transform 0.2s' }}
            >
              <MenuIcon sx={{ fontSize: 28 }} />
            </IconButton>
          </Box>
        </Container>
      </AppBar>
      <BurgerMenu open={drawerOpen} onClose={handleDrawerToggle} />
    </>
  );
};

export default Navbar;
