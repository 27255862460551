import React, { useState } from 'react';
import { Button, TextField, Stack, Typography, Checkbox, FormControlLabel } from '@mui/material';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false); // For GDPR compliance

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!consent) {
      alert('Please agree to the GDPR terms.');
      return;
    }
    const form = e.target;
    form.submit();
  };

  return (
    <form
      action="https://charisopoulou.us22.list-manage.com/subscribe/post?u=627821fc5799c12ccd39a3502&amp;id=57ec42e23d&amp;f_id=0065d5e1f0" // Mailchimp URL
      method="POST"
      onSubmit={handleSubmit}
      target="_blank"
    >
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <TextField
          placeholder="example@email.com"
          variant="outlined"
          size="small"
          name="EMAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={{
            width: '60%',
            backgroundColor: 'common.white',
            borderRadius: '50px',
            '& fieldset': {
              borderRadius: '50px',
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{
            borderRadius: '50px',
            px: 4,
            textTransform: 'none',
          }}
        >
          Εγγραφή
        </Button>
      </Stack>

      {/* GDPR Consent Checkbox */}
      <FormControlLabel
        control={
            <Checkbox
            checked={consent}
            onChange={() => setConsent(!consent)}
            required // Keeping required for validation purposes
            />
        }
        label={
            <Typography variant="body2">
            Συμφωνώ να λαμβάνω το newsletter και αποδέχομαι την πολιτική απορρήτου.
            </Typography>
        }
        sx={{
            mt: 2,
            '& .MuiFormControlLabel-asterisk': { // Target the asterisk class to hide it
            display: 'none',
            }
        }}
        />
    </form>
  );
};

export default NewsletterForm;
