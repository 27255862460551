// src/App.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Loader from './components/Loader'; // Import the custom loader
import ThankYouPage from './pages/ThankYouPage'; // Import the ThankYouPage


// Lazy load the pages for code splitting
const LandingPage = lazy(() => import('./pages/LandingPage'));
const Biography = lazy(() => import('./pages/BiographyPage'));
const Contact = lazy(() => import('./pages/ContactPage'));
const Services = lazy(() => import('./pages/ServicesPage'));
const ServiceDetail = lazy(() => import('./pages/ServiceDetailPage')); // Import ServiceDetailPage
const Articles = lazy(() => import('./pages/ArticlesPage'));
const ArticleDetail = lazy(() => import('./pages/ArticleDetailPage'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const PersonalData = lazy(() => import('./pages/PersonalData'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));

// ErrorBoundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to an external service if needed
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again later.</h1>;
    }

    return this.props.children;
  }
}

const App = () => {
  return (
    <Router>
      <Navbar />
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/βιογραφία" element={<Biography />} />
            <Route path="/υπηρεσίες" element={<Services />} />
            <Route path="/υπηρεσίες/:slug" element={<ServiceDetail />} /> {/* Updated Route */}
            <Route path="/άρθρα" element={<Articles />} />
            <Route path="/άρθρα/:slug" element={<ArticleDetail />} /> {/* Updated Route */}
            <Route path="/επικοινωνία" element={<Contact />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/personal-data" element={<PersonalData />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
          </Routes>
          <Footer />
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
