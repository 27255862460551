// src/components/Loader.js
import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh', // Center the loader vertically
      }}
    >
      <CircularProgress size={60} /> {/* Material-UI Circular Progress Component */}
    </Box>
  );
};

export default Loader;
