import React from 'react';
import { TextField, Box, Typography, Button, useTheme } from '@mui/material';

const ContactDetailsStep = ({ formik, handleNext, handleBack }) => {
  const theme = useTheme(); // Access the theme

  return (
    <Box sx={{ textAlign: 'center' }}>
      {/* Title */}
      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        Στοιχεία Επικοινωνίας
      </Typography>

      {/* Name, Phone, Email Fields */}
      {['name', 'phone', 'email'].map((field, index) => (
        <TextField
          key={index}
          fullWidth
          id={field}
          name={field}
          label={field === 'name' ? 'Όνομα' : field === 'phone' ? 'Τηλέφωνο' : 'Email'}
          value={formik.values[field]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[field] && Boolean(formik.errors[field])}
          helperText={formik.touched[field] && formik.errors[field]}
          sx={{
            mt: 2,
            mb: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '30px',
            },
          }}
        />
      ))}

      {/* Navigation Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
          variant="outlined"
          onClick={handleBack}
          sx={{
            textTransform: 'none',
            borderRadius: '30px',
            borderColor: theme.palette.primary.main, // Use theme color
            color: theme.palette.primary.main, // Use theme color for text
          }}
        >
          Πίσω
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={!(formik.values.name && formik.values.phone && formik.values.email)}
          sx={{
            backgroundColor: theme.palette.primary.main, // Use theme color
            borderRadius: '30px',
            textTransform: 'none',
          }}
        >
          Επόμενο βήμα
        </Button>
      </Box>
    </Box>
  );
};

export default ContactDetailsStep;
