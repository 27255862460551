import React from 'react';
import { Typography, Box, Button, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'; // Happy face for success
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'; // Sad face for error

const ResultStep = ({ status, onClose }) => {
  const theme = useTheme(); // Access the theme

  // Determine if it is success or error based on the status prop
  const isSuccess = status === 'success';

  return (
    <Box sx={modalStyle}>
      <IconButton
        sx={{ position: 'absolute', top: 8, right: 8 }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      {/* Conditional Rendering for Success/Error */}
      {isSuccess ? (
        <>
          <SentimentSatisfiedAltIcon sx={{ ...iconStyle, color: theme.palette.primary.main }} /> {/* Use theme color */}
          <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
            Το ραντεβού σας καταχωρήθηκε επιτυχώς!
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: 'gray' }}>
            Σας ευχαριστούμε που κλείσατε το ραντεβού σας. Θα επικοινωνήσουμε
            μαζί σας σύντομα για επιβεβαίωση.
          </Typography>
        </>
      ) : (
        <>
          <SentimentDissatisfiedIcon sx={{ ...iconStyle, color: theme.palette.primary.main }} /> {/* Use theme color */}
          <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
            Παρουσιάστηκε κάποιο πρόβλημα.
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, color: 'gray' }}>
            Παρακαλούμε δοκιμάστε ξανά. Αν το πρόβλημα συνεχιστεί, επικοινωνήστε
            μαζί μας.
          </Typography>
        </>
      )}

      {/* Close Button */}
      <Button
        variant="contained"
        onClick={onClose}
        sx={{
          backgroundColor: theme.palette.primary.main, // Use theme color
          borderRadius: '30px',
          textTransform: 'none',
        }}
      >
        Κλείσιμο
      </Button>
    </Box>
  );
};

// Styles
const modalStyle = {
  textAlign: 'center',
  position: 'relative',
  padding: '20px',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const iconStyle = {
  fontSize: '80px',
};

export default ResultStep;
