// src/components/BookingButton.js
import React, { useState } from 'react';
import { Button } from '@mui/material';
import BookingPopup from './BookingPopup';

  const BookingButton = ({ buttonText = "Κλείσε Ραντεβού", ...props }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{
          textTransform: 'none',
          borderRadius: '20px',
          padding: '1rem 1.5rem',
          width: { xs: '100%', sm: 'auto' },
          boxShadow: '0 7px 30px rgba(211, 155, 123, 0.4)',
          '&:hover': { boxShadow: '0 7px 30px rgba(211, 155, 123, 0.5)' },
          ...props.sx, // Allow additional styling overrides
        }}
      >
        {buttonText}
      </Button>
      <BookingPopup open={open} onClose={handleClose} />
    </>
  );
};

export default BookingButton;
