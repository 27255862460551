import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import MoodIcon from '@mui/icons-material/Mood';

const ThankYouPage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100vw',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: { xs: '2rem', md: '4rem' }, // Shortened padding syntax
        boxSizing: 'border-box',
        overflowX: 'hidden',
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.1)',
          borderRadius: 2, // Used Material-UI's spacing factor
          py: { xs: 3, md: 5 }, // Adjusted padding for better UX on mobile/tablet
          px: { xs: 2, md: 4 },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: { xs: '2rem', md: '3rem' },
            mb: { xs: 2, md: 3 },
          }}
        >
          Ευχαριστούμε!
        </Typography>

        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.primary,
            mb: { xs: 3, md: 4 },
            fontSize: { xs: '1rem', md: '1.25rem' },
          }}
        >
          Η εγγραφή σας στο newsletter ήταν επιτυχής. Ανυπομονούμε να σας παρέχουμε συμβουλές για την ψυχική σας ευεξία.
        </Typography>

        <MoodIcon
          sx={{
            fontSize: { xs: '3rem', md: '4rem' },
            color: theme.palette.secondary.main,
            mb: { xs: 3, md: 4 },
          }}
        />

        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/"
          sx={{
            textTransform: 'none',
            borderRadius: 2,
            py: { xs: 1, md: 1.5 }, // Vertical padding for touch area
            px: { xs: 2, md: 3 }, // Horizontal padding for better tap area
            fontSize: { xs: '1rem', md: '1.125rem' },
          }}
        >
          Επιστροφή στην Αρχική Σελίδα
        </Button>
      </Container>
    </Box>
  );
};

export default ThankYouPage;
