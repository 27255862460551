// emailService.js
import emailjs from 'emailjs-com';

const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
/**
 * Sends an email using EmailJS
 * @param {Object} templateParams - The parameters for the email template
 * @returns {Promise} - A promise that resolves if the email is sent successfully, and rejects on failure.
 */
export const sendEmail = (templateParams) => {
  return emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID);
};
